import * as React from "react";
import Layout from "../components/layout";

const TestimonialsPage = () => {
  return (
    <Layout>
      <h1 className="title">Testimonials</h1>
    </Layout>
  );
};

export default TestimonialsPage;
